//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-700:_9544,_8972,_2152,_1696,_8316,_8396,_7684,_752;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-700')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-700', "_9544,_8972,_2152,_1696,_8316,_8396,_7684,_752");
        }
      }catch (ex) {
        console.error(ex);
      }